import React from 'react'

import VideoCardView from './VideoCardView'
import useVideoCard from '../useVideoCard'

interface Props {
  videoId: string
}

const VideoCard = ({ videoId }: Props) => {
  const state = useVideoCard(videoId)
  return (
    <VideoCardView {...state} />
  )
}

export default VideoCard
