import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { RootState } from '../../redux/reducers'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import { useFindQuery } from '../../query'

const DEFAULT_SORT = {
  createdAt: '-1'
}
const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 12

const useVideosList = () => {
  const videosState = useSelector((state: RootState) => state.videos)

  const history = useHistory()
  const location = useLocation()

  let targetsQuery = useMemo(
    () => {
      let query = qs.parse(location.search.substr(1))
      if (query.targetId) {
        return { targetId: query.targetId }
      }
      return {}
    },
    [location.search]
  )

  const {
    data: videoTargetsPaginated
  } = useFindQuery('videoTargets', {
    $limit: 500,
    ...targetsQuery
  })

  let query = useMemo(
    () => {
      let query = qs.parse(location.search.substr(1))

      if (!query.targetId) {
        delete query._id
      }

      if (videoTargetsPaginated?.data.length && query.targetId) {
        delete query.targetId
        const videoIds = videoTargetsPaginated.data.map(videoTarget => videoTarget.videoId)
        query._id = { $in: videoIds }
      }

      if (query.levelId) {
        query.$and = [
          { $or: [ { levelId: query.levelId }, { levelId: { $exists: false } }] }
        ]
        delete query.levelId
      }

      if (query.name) {
        const search = query.name
        query.name = { $regex: search, $options: 'i' }
      }

      // set default query options here
      if (!query['$sort']) {
        query['$sort'] = DEFAULT_SORT
      }

      if (!query['$skip']) {
        query['$skip'] = DEFAULT_SKIP
      }

      if (!query['$limit']) {
        query['$limit'] = DEFAULT_LIMIT
      }

      query.programId = { $exists: false }

      return query
    },
    [location.search, videoTargetsPaginated]
  )

  const {
    data: videosPaginated,
    status: videoStatus
  } = useFindQuery('videos', { ...query, videoPodcastId: 'null' })
  const {
    data: levelsPaginated,
    status: levelStatus
  } = useFindQuery('levels', {
    $limit: 50
  })
  const {
    data: targetsPaginated,
    status: targetStatus
  } = useFindQuery('targets', {
    $limit: 50
  })
  const {
    data: equipmentsPaginated,
    status: equipmentStatus
  } = useFindQuery('equipments', {
    $limit: 50
  })

  const updateQueryParams = useCallback((query, resetPagination: boolean = true) => {
    const newQuery = { ...query }
    delete newQuery._id
    if (newQuery.$and?.$or) {
      newQuery.levelId = newQuery.$and.$or[0].levelId
    }
    delete newQuery.$and

    if (newQuery.name?.$regex) {
      newQuery.name = newQuery.name.$regex
      delete newQuery.name.$regex
    }

    if (resetPagination) {
      newQuery['$skip'] = DEFAULT_SKIP
      newQuery['$limit'] = DEFAULT_LIMIT
    }
    history.replace(`${history.location.pathname}?${qs.stringify(newQuery)}`)
  }, [])

  const handleTargetFilterChange = useCallback((value: any) => {
    const newQuery = qs.parse(location.search.substr(1))

    switch (value) {
      case 'all': {
        delete newQuery.targetId
        break
      }
      default: {
        newQuery.targetId = value
      }
    }

    updateQueryParams(newQuery)
  },[location.search])

  const handleLevelFilterChange = useCallback((value: any) => {
    const newQuery = qs.parse(location.search.substr(1))

    switch (value) {
      case 'all': {
        delete newQuery.levelId
        break
      }
      default: {
        newQuery.levelId = value
      }
    }

    updateQueryParams(newQuery)
  },[location.search])

  const handleDurationFilterChange = useCallback((value: any) => {
    const newQuery = qs.parse(location.search.substr(1))

    switch (value) {
      case 'all': {
        delete newQuery.duration
        break
      }
      default: {
        newQuery.duration = JSON.parse(value)
      }
    }

    updateQueryParams(newQuery)
  },[location.search])

  const handleEquipmentFilterChange = useCallback((value: any) => {
    const newQuery = qs.parse(location.search.substr(1))

    switch (value) {
      case 'all': {
        delete newQuery.equipmentId
        break
      }
      default: {
        newQuery.equipmentId = value
      }
    }

    updateQueryParams(newQuery)
  },[location.search])

  const handlePaginateChange = useCallback((page, pageSize) => {
    const newQuery = qs.parse(location.search.substr(1))

    newQuery['$skip'] = (page - 1) * pageSize

    updateQueryParams(newQuery, false)
  }, [location.search])

  const initialValues = useMemo(() => {
    let newQuery = { ...query }
    if (newQuery.$and?.$or) {
      newQuery.levelId = newQuery.$and.$or[0].levelId
      delete newQuery.$and
    }
    return {
      target: query.targetId || 'all',
      level: newQuery.levelId || 'all',
      duration: JSON.stringify(query.duration) || 'all',
      equipment: query.equipmentId || 'all'
    }
  }, [])

  const currentPage = useMemo(() => {
    return query['$skip'] / query['$limit'] + 1
  }, [query])

  return {
    ...videosState,
    handleTargetFilterChange,
    handleLevelFilterChange,
    handleDurationFilterChange,
    handleEquipmentFilterChange,
    handlePaginateChange,
    videosList: videosPaginated?.data,
    targetsList: targetsPaginated?.data,
    levelsList: levelsPaginated?.data,
    equipmentsList: equipmentsPaginated?.data,
    videoStatus,
    targetStatus,
    levelStatus,
    equipmentStatus,
    initialValues,
    pageSize: DEFAULT_LIMIT,
    total: videosPaginated?.total || 0,
    currentPage: currentPage
  }
}
export default useVideosList
