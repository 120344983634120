import { Card, Descriptions, Tooltip } from 'antd'
import { CloseOutlined, HeartFilled, HeartOutlined, PlusOutlined } from '@ant-design/icons/lib'
import React from 'react'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import { Level } from '../../providers/ClientProvider/client/services/levels/types'
import { Equipment } from '../../providers/ClientProvider/client/services/equipments/types'
import './videoCard.less'

interface IProps {
  video?: Video,
  thumbnail?: string
  level?: Level,
  equipment?: Equipment,
  favorite: boolean,
  toggleFavorite: (e: React.MouseEvent<HTMLElement>) => void,
  planned: boolean,
  togglePlanned: (e: React.MouseEvent<HTMLElement>) => void,
  watched: boolean
  handleCardClick: () => void
  clientId: string | null
  videoStatus: 'loading' | 'error' | 'success'
  levelStatus: 'loading' | 'error' | 'success'
  equipmentStatus: 'loading' | 'error' | 'success'
  favoriteStatus: 'loading' | 'error' | 'success'
  watchedStatus: 'loading' | 'error' | 'success'
  plannedStatus: 'loading' | 'error' | 'success'
}

const VideoCardView = (props: IProps) => (
  <Card
    className='video-card'
    loading={!(props.video)}
    hoverable
    onClick={props.handleCardClick}
    cover={
      <img
        src={props.thumbnail}
        alt={props.video?.name}
      />
    }
    actions={props.clientId ? [
      <Tooltip
        title={props.favorite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
        placement='left'
      >
        {props.favorite
          ? <HeartFilled
            onClick={props.toggleFavorite}
          />
          : <HeartOutlined
            onClick={props.toggleFavorite}
          />}
      </Tooltip>,
      <Tooltip
        title={props.planned ? 'Usuń z zaplanowanych' : 'Dodaj do zaplanowanych'}
        placement='left'
      >
        {props.planned
          ? <CloseOutlined
            onClick={props.togglePlanned}
          />
          : <PlusOutlined
            onClick={props.togglePlanned}
          />}
      </Tooltip>
    ] : undefined}
  >
    <Card.Meta
      title={props.video?.name}
      description={
        <Descriptions
          column={1}
          size='small'
        >
          <Descriptions.Item label='Poziom' className='video-card__descriptions-item'>
            {props.level?.name}
          </Descriptions.Item>
          <Descriptions.Item label='Sprzęt' className='video-card__descriptions-item'>
            {props.equipment?.name}
          </Descriptions.Item>
          <Descriptions.Item label='Czas trwania' className='video-card__descriptions-item'>
            {props.video?.duration ? (props.video?.duration / 60).toFixed(0) : 0} min
          </Descriptions.Item>
        </Descriptions>
      }
    />
  </Card>
)

export default VideoCardView
