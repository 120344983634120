import { useFindQuery } from '../../query'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'

const useMyWatched = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)

  const {
    data: watched,
    status: watchedStatus
  } = useFindQuery('watchedVideos',
    clientId
      ? {
        clientId: clientId,
        $limit: 100
      }
      : false
  )

  return {
    watchedList: watched?.data,
    watchedStatus
  }
}

export default useMyWatched
