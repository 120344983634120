import React from 'react'
import { List, Typography } from 'antd'
import VideoCard from '../../components/VideoCard/'
import { Planned } from '../../providers/ClientProvider/client/services/planned/types'
import MainImage from '../../components/MainImage/MainImage'
import './myPlanned.less'

interface Props {
  plannedList?: Planned[],
  plannedStatus: 'loading' | 'error' | 'success'
}

const { Title } = Typography

const MyPlannedView = (props: Props) => {
  return (
    <div className='my-planned'>
      <MainImage image='my_fav_o1qr3m' />
      <div className='my-planned__title'>
        <Title>Zaplanowane filmy</Title>
        <Title level={2}>
          Chcesz zaplanować nowy film na jutro? Zapisz go w tym miejscu aby go nie zgubić i nie marnować czasu na ponowne wyszukiwanie.
        </Title>
      </div>
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        dataSource={props.plannedList}
        renderItem={item => (
          <List.Item>
            <VideoCard
              videoId={item.videoId}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default MyPlannedView
