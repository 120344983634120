import { useFindQuery } from '../../query'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'

const useMyFavorites = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const {
    data: favorites,
    status: favoriteStatus
  } = useFindQuery('favoriteVideos',
    clientId
      ? {
        clientId: clientId,
        $limit: 100
      }
      : false
  )

  return {
    favoritesList: favorites?.data,
    favoriteStatus
  }
}

export default useMyFavorites
