import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { useFindQuery } from '../../query'

const useVideoItem = (id: string | undefined) => {

  const history = useHistory()
  const clientId = useSelector((state: RootState) => state.appState.clientId)

  const { data: watched, status: watchedStatus } = useFindQuery('watchedVideos', clientId
    ? { clientId }
    : false
  )

  const { data: videos, status: videosStatus } = useFindQuery('videos', {
    _id: { $nin: watched?.data ? [...watched.data.map(video => video.videoId), id] : [id] },
    $limit: 4
  })

  const handleSubscribe = () => {
    history.push('/select-subscription')
  }
  return {
    handleSubscribe,
    videos: videos?.data
  }
}

export default useVideoItem
