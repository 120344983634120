import { useFindQuery } from '../../query'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'

const useMyFavorites = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)

  const {
    data: planned,
    status: plannedStatus
  } = useFindQuery('plannedVideos',
    clientId
      ? {
        clientId: clientId,
        $limit: 100
      }
      : false
  )

  return {
    plannedList: planned?.data,
    plannedStatus
  }
}

export default useMyFavorites
