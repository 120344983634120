import React from 'react'
import { List, Typography } from 'antd'
import { Favorite } from '../../providers/ClientProvider/client/services/favorites/types.d'
import VideoCard from '../../components/VideoCard/'
import MainImage from '../../components/MainImage/MainImage'
import './myFavorites.less'

interface Props {
  favoritesList?: Favorite[],
  favoriteStatus: 'loading' | 'error' | 'success'
}
const { Title } = Typography

const MyFavoritesView = (props: Props) => {
  return (
    <div className='my-favorites'>
      <MainImage image='my_fav_o1qr3m' style={{ objectPosition: '50% 100%' }} />
      <div className='my-favorites__title'>
        <Title>Moje ulubione filmy</Title>
        <Title level={2}>Zaznaczaj swoje ulubione filmy aby móc do nich zawsze wrócić bez konieczności ponownego wyszukiwania.</Title>
      </div>
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        dataSource={props.favoritesList}
        renderItem={item => (
          <List.Item>
            <VideoCard
              videoId={item.videoId}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default MyFavoritesView
