import React from 'react'
import { List, Typography } from 'antd'
import VideoCard from '../../components/VideoCard/'
import { Watched } from '../../providers/ClientProvider/client/services/watchedVideos/types'
import MainImage from '../../components/MainImage/MainImage'
import './myWatched.less'

interface Props {
  watchedList?: Watched[],
  watchedStatus: 'loading' | 'error' | 'success'
}

const { Title } = Typography

const MyWatchedView = (props: Props) => {
  return (
    <div className='my-watched'>
      <MainImage image='my_fav_o1qr3m' />
      <div className='my-watched__title'>
        <Title>Obejrzane filmy</Title>
        <Title level={2}>
          Nie jesteś pewna czy dany film już obejrzałaś? A może chcesz powtórzyć serię ćwiczeń, którą zrobiłaś? Nie musisz szukać filmu. W tym miejscu znajdziesz wszystkie lekcje i programy, które odtworzyłaś i obejrzałaś.
        </Title>
      </div>
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        dataSource={props.watchedList}
        renderItem={item => (
          <List.Item>
            <VideoCard
              videoId={item.videoId}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default MyWatchedView
